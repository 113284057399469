import React from 'react';
import { Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { styleConfig } from '../../config/config';

import { smoothScrollToElement } from '../utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 900,
    position: 'fixed',
    bottom: 115,
    right: 50,
    borderRadius: '50%',
    width: 50,
    height: 50,
    backgroundColor: styleConfig.color.bg.darkergrey,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 0,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: styleConfig.color.bg.darkgrey,
    },
    [theme.breakpoints.down('md')]: {
      right: 25,
      bottom: 85,
    },
  },
  icon: {
    color: styleConfig.color.text.white,
    fontSize: 42,
  },
}));

const BackToTop: React.FC<BackToTopProps> = (props) => {
  const classes = useStyles();

  const { isVisible, anchorId } = props;

  return (
    <Fade in={isVisible}>
      <button onClick={() => smoothScrollToElement(anchorId)} className={classes.root}>
        <KeyboardArrowUpIcon classes={{ root: classes.icon }} />
      </button>
    </Fade>
  );
};

interface BackToTopProps {
  isVisible: boolean;
  anchorId: string;
}

export default BackToTop;
