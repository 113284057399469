import React from 'react';
import { Grid, Typography, Container, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { IAppState, LeaseType } from '../defs/declarations';
import { WordpressWpCarEdge } from '../defs/generated-models';

import InputSelect from '../components/InputSelect';
import { searchSortOptions, searchResultsPerPageOptions } from '../constants';

import {
  handleFilterVariants,
  handleUpdateSort,
  handleUpdatePostsPerPage,
} from '../actions/specialOffers';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 30,
    marginTop: 50,
    [theme.breakpoints.down('lg')]: {
      marginTop: 150,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  total: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  optionRoot: {
    display: 'flex',
    alignItems: 'flex-end',
    '& > p': {
      marginRight: 15,
    },

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  alignRight: {
    justifyContent: 'flex-end',

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}));

const SearchHeader: React.FunctionComponent<SearchHeaderProps> = (props) => {
  const {
    agreementTerms,
    annualMileage,
    bodyStyles,
    brands,
    fuelType,
    handleFilterVariants,
    handleUpdatePostsPerPage,
    handleUpdateSort,
    page,
    postsPerPage,
    initialRental,
    initialVariants,
    leaseType,
    postsToShow,
    price,
    sortValue,
    transmission,
    variants,
  } = props;

  const classes = useStyles();

  const handlePerPage = (val) => {
    handleUpdatePostsPerPage(val, page);
  };

  const handleSort = (val) => {
    if (val !== sortValue) {
      handleUpdateSort(val);

      handleFilterVariants(
        false,
        leaseType,
        agreementTerms,
        initialRental,
        annualMileage,
        initialVariants,
        variants,
        fuelType,
        brands,
        bodyStyles,
        transmission,
        price,
        val,
        postsPerPage
      );
    }
  };

  return (
    <Container maxWidth="xl" className={classes.root} id="search-header">
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          {variants.length >= 1 && (
            <Typography className={classes.total}>
              <strong>{postsToShow}</strong> of <strong>{variants.length}</strong> results
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className={classes.optionRoot}>
            <Typography>Items per page:</Typography>
            <InputSelect
              handleChange={handlePerPage}
              menuItems={searchResultsPerPageOptions}
              name="perPage"
              value={postsPerPage}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className={clsx([classes.optionRoot, classes.alignRight])}>
            <Typography>Sort by:</Typography>
            <InputSelect
              handleChange={handleSort}
              menuItems={searchSortOptions}
              name="sort"
              value={sortValue}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    agreementTerms: state.specialOffers.agreementTerms,
    annualMileage: state.specialOffers.annualMileage,
    bodyStyles: state.specialOffers.bodyStyles,
    brands: state.specialOffers.brands,
    fuelType: state.specialOffers.fuelType,
    initialRental: state.specialOffers.initialRental,
    initialVariants: state.specialOffers.initialVariants,
    isFiltered: state.specialOffers.isFiltered,
    leaseType: state.specialOffers.leaseType,
    page: state.specialOffers.page,
    postsPerPage: state.specialOffers.postsPerPage,
    postsToShow: state.specialOffers.postsToShow,
    price: state.specialOffers.price,
    sortValue: state.specialOffers.sortValue,
    transmission: state.specialOffers.transmission,
    variants: state.specialOffers.variants,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleFilterVariants,
      handleUpdateSort,
      handleUpdatePostsPerPage,
    },
    dispatch
  );

interface SearchHeaderProps {
  agreementTerms: number;
  annualMileage: number;
  bodyStyles: [string];
  brands: [string];
  fuelType: string;
  handleUpdatePostsPerPage: Function;
  handleFilterVariants: Function;
  handleUpdateSort: Function;
  initialRental: number;
  initialVariants: Array<WordpressWpCarEdge>;
  isFiltered: boolean;
  leaseType: LeaseType;
  page: number;
  postsPerPage: number;
  postsToShow: number;
  price: Array<number>;
  sortValue: string;
  transmission: string;
  variants: Array<WordpressWpCarEdge>;
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchHeader);
