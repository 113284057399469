import React from 'react';
import { Theme, Container, Grid, Box } from '@material-ui/core';
import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import { makeStyles } from '@material-ui/core/styles';

// Components
import Title from './Common/Title';

// Config
import { styleConfig } from '../../config/config';

const HeroStatic: React.FunctionComponent<HeroStatic> = ({
  children,
  subtitle,
  headline,
  content,
  isSmall,
  image,
  isMedium,
  isLarge,
}) => {
  const headerHeight = (isLarge && 1200) || (isMedium && 650) || (isSmall && 450) || 852;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      position: 'relative',
      height: headerHeight,
      minHeight: 345,
      width: '100%',
      background: styleConfig.color.bg.darkgrey,

      [theme.breakpoints.down('lg')]: {
        height: `calc(${headerHeight}px -  105px)`,
      },

      [theme.breakpoints.down('sm')]: {
        height: `calc(${headerHeight}px -  105px)`,
      },

      [theme.breakpoints.down('xs')]: {
        height: isSmall || isMedium || isLarge ? `calc(${headerHeight}px - 105px)` : '550px',
      },
    },
    image: {
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    },
    slideContentFixed: {
      position: 'absolute',
      left: '11%',
      zIndex: 80,
      maxWidth: '90%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      [theme.breakpoints.down('md')]: {
        left: '5%',
      },

      [theme.breakpoints.down('sm')]: {
        top: -30,
      },
    },
    slide: {
      position: 'relative',
      zIndex: 750,
      height: isSmall ? 'calc(450px + 2px)' : 'calc(852px + 2px)',

      [theme.breakpoints.down('lg')]: {
        height: `calc(${headerHeight}px -  105px)`,
      },

      [theme.breakpoints.down('sm')]: {
        height: `calc(${headerHeight}px -  105px)`,
      },

      [theme.breakpoints.down('xs')]: {
        height: isSmall || isMedium || isLarge ? `calc(${headerHeight}px - 105px)` : '550px',
      },
    },
    slideText: {
      color: styleConfig.color.text.white,
      marginBottom: 40,
      maxWidth: '45%',

      [theme.breakpoints.down('md')]: {
        maxWidth: '60%',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },
  }));

  const staticImage = getImage(image);
  const bgImage = convertToBgImage(staticImage);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BackgroundImage fadeIn={false} Tag="section" className={classes.image} {...bgImage}>
        <div className={classes.image}>
          <Container maxWidth="xl">
            <Grid container justifyContent="center" direction="column" className={classes.slide}>
              <div className={classes.slideContentFixed}>
                <Title
                  component="h1"
                  isSubtitle={typeof subtitle === 'string'}
                  subtitleText={(subtitle && subtitle) || ''}
                  subtitleType="subtitle1"
                  titleCaps
                  titleColor="white"
                  titleHeavy
                  titleSize="h1"
                  titleText={(headline && headline) || ''}
                  marginBottom={50}
                />

                {content && (
                  <Box
                    component="div"
                    key="hero-content"
                    className={classes.slideText}
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                )}
              </div>

              {children && children}
            </Grid>
          </Container>
        </div>
      </BackgroundImage>
    </div>
  );
};

interface HeroStatic {
  children?: React.ReactNode;
  isMonth?: boolean;
  subtitle?: string;
  headline: string;
  content?: string;
  isSmall?: boolean;
  isMedium?: boolean;
  isLarge?: boolean;
  image?: any;
}

export default HeroStatic;
