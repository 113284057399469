import React from 'react';
import { bindActionCreators } from 'redux';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';

import { IAppState } from '../defs/declarations';

import { handleDisplayNotification } from '../actions/notifications';

const useStyles = makeStyles(() => ({
  rootContent: {
    borderRadius: 0,
    padding: '0 15px',
  },
  contentInner: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
  },
  icon: {
    marginRight: 10,
    fontSize: 32,
  },
}));

const Notifications: React.FC<NotificationProps> = (props) => {
  const classes = useStyles();
  const { handleDisplayNotification, isActive, message } = props;

  const handleClose = () => {
    handleDisplayNotification(false, '');
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={isActive}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <SnackbarContent
        classes={{ root: classes.rootContent }}
        message={
          <div className={classes.contentInner}>
            <InfoIcon className={classes.icon} /> <span>{message}</span>
          </div>
        }
      />
    </Snackbar>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    isActive: state.notifications.isActive,
    message: state.notifications.message,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleDisplayNotification,
    },
    dispatch
  );

interface NotificationProps {
  isActive: boolean;
  message: string;
  handleDisplayNotification: Function;
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
