import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import { Grid, Typography, Container, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import ReplayIcon from '@material-ui/icons/Replay';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import queryString from 'query-string';
import isArray from 'lodash/isArray';

// Defs
import { IAppState } from '../defs/declarations';
import { WordpressWpCarEdge } from '../defs/generated-models';

// Config
import { styleConfig } from '../../config/config';

// Actions
import {
  handleInitialVariants,
  handleGetInitialPreferences,
  handleUpdatePage,
  handleFilterVariants,
} from '../actions/specialOffers';

// Components
import HeroStatic from '../components/HeroStatic';
import LayoutPage from '../components/LayoutPage';
import Loading from '../components/LeaseBuilder/CustomiseLease/Steps/Loading';
import MuiButton from '../components/MuiButton';
import PostsList from '../components/PostsList';
import SearchHeader from '../components/SearchHeader';
import Section from '../components/Common/Section';
import SEO from '../components/SEO';
import SpecialOffersItem from '../components/SpecialOffersItem';
import Notifications from '../components/Notifications';
import BackToTop from '../components/BackToTop';
import { smoothScrollToElement } from '../utils/utils';
import ContentModulesCallToActionPrimary from '../components/ContentModuleCallToActionPrimary';
import {
  fuelTypesCap,
  searchPreSelectedBrands,
  bodyTypes,
  leaseTypeButtons,
  transmissionTypes,
  initialRentalMarks,
  agreementTermsMarks,
  annualMileageMarks,
} from '../constants';
import SearchPreferencesWidget from '../components/SearchPreferencesWidget';

const useStyles = makeStyles(() => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  footerTitle: {
    marginBottom: 50,
    color: styleConfig.color.text.black,
  },
  noResults: {
    minHeight: 350,
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 75px',
    '& > p': {
      fontSize: 22,
      fontWeight: 600,
    },
  },
  noResultsIcon: {
    fontSize: 80,
  },
  buttonIcon: {
    marginRight: 5,
  },
  loadMorePosts: {
    marginTop: 50,
    marginBottom: 50,
    '& button': {
      marginRight: 15,
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  loadMoreCount: {
    marginBottom: 10,
    fontSize: 14,
    textAlign: 'center',
  },
}));

const SearchPage: React.FunctionComponent<SeachPageProps> = (props) => {
  const {
    agreementTerms,
    annualMileage,
    handleFilterVariants,
    handleGetInitialPreferences,
    handleInitialVariants,
    handleUpdatePage,
    initialRental,
    isLoading,
    isLoadingPosts,
    page,
    postsPerPage,
    postsToShow,
    variants,
    leaseType,
    fuelType,
    brands,
    bodyStyles,
    sortValue,
    transmission,
    price,
  } = props;

  const { data } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    /**
     * Handle the query params if they exist for each filterable option
     * If the query param does not exist then it will be ignored in favour of the defaults or the saved preferences
     * @example /search?route=home&brands=jaguar&brands=audi&brands=land-rover&leaseType=business&bodyStyles=Coupe&fuelType=Petrol&transmission=Manual&price=500&price=900&initialRental=6&agreementTerms=36&annualMileage=12000
     */
    let queryParams;
    if (typeof window !== 'undefined') {
      queryParams = queryString.parse(window.location.search);
    }
    let queryFuelType;
    let queryLeasetype;
    let queryTransmission;
    let queryInitialRental;
    let queryAgreementTerms;
    let queryAnnualMileage;
    let queryBrands;
    let queryBodyStyles;
    let queryPrice;
    if (fuelTypesCap.map((x) => x.label).includes(queryParams.fuelType)) {
      queryFuelType = queryParams.fuelType;
    }
    if (leaseTypeButtons.map((x) => x.label).includes(queryParams.leaseType)) {
      queryLeasetype = queryParams.leaseType;
    }
    if (transmissionTypes.includes(queryParams.transmission)) {
      queryTransmission = queryParams.transmission;
    }
    if (initialRentalMarks.map((x) => x.label).includes(queryParams.initialRental)) {
      queryInitialRental = parseInt(queryParams.initialRental);
    }
    if (agreementTermsMarks.map((x) => x.label).includes(queryParams.agreementTermsMarks)) {
      queryAgreementTerms = parseInt(queryParams.agreementTerms);
    }
    if (annualMileageMarks.map((x) => x.label).includes(queryParams.annualMileageMarks)) {
      queryAnnualMileage = parseInt(queryParams.annualMileage);
    }
    // Recreate the input value for price if the query string exists
    if (queryParams.price && isArray(queryParams.price)) {
      queryPrice = queryParams.price.map((price) => parseInt(price));
    } else {
      queryPrice = [150, 1500]; // default values for pricing
    }
    // Recreate the input value for bodyStyles if the query string exists
    if (queryParams.bodyStyles && isArray(queryParams.bodyStyles)) {
      // Only use valid values
      queryBodyStyles = queryParams.bodyStyles
        .filter((bodyType) => bodyTypes.map((bodyType) => bodyType.label).includes(bodyType))
        .map((bodyType) => {
          return { value: bodyType, label: bodyType };
        });
    }
    // If it is a single value it should still match the input array if the value is valid
    else if (queryParams.bodyStyles) {
      if (bodyTypes.map((b) => b.label.includes(queryParams.bodyStyles))) {
        queryBodyStyles = [{ label: queryParams.bodyStyles, value: queryParams.bodyStyles }];
      }
    } else {
      queryBodyStyles = [];
    }
    // Recreate the input value for brands if the query string exists
    if (queryParams.brands && isArray(queryParams.brands)) {
      queryBrands = queryParams.brands
        .filter((brand) => searchPreSelectedBrands.map((brand) => brand.value).includes(brand))
        .map((brand) => {
          return { value: brand, label: brand.charAt(0).toUpperCase() + brand.slice(1) };
        });
    } else if (queryParams.brands) {
      // If it is a single value it should still match the input array if the value is valid
      if (searchPreSelectedBrands.map((b) => b.value.includes(queryParams.brands))) {
        queryBrands = [
          {
            label: queryParams.brands.charAt(0).toUpperCase() + queryParams.brands.slice(1),
            value: queryParams.brands,
          },
        ];
      }
    } else {
      queryBrands = [];
    }

    //Dispatch actions to set the initial data and pre-filtering based on query params
    handleGetInitialPreferences();
    handleInitialVariants(data.allWordpressWpCar.edges);
    handleFilterVariants(
      false,
      queryLeasetype ? queryLeasetype : leaseType,
      queryAgreementTerms ? queryAgreementTerms : agreementTerms,
      queryInitialRental ? queryInitialRental : initialRental,
      queryAnnualMileage ? queryAnnualMileage : annualMileage,
      data.allWordpressWpCar.edges,
      data.allWordpressWpCar.edges,
      queryFuelType ? queryFuelType : fuelType,
      queryBrands ? queryBrands : brands,
      queryBodyStyles ? queryBodyStyles : bodyStyles,
      queryTransmission ? queryTransmission : transmission,
      queryPrice ? queryPrice : price,
      sortValue,
      postsPerPage
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const handlePage = () => {
    handleUpdatePage(page, postsPerPage);
  };

  const handleRestart = () => {
    smoothScrollToElement('filter-widget');
  };

  let count = 0;

  return (
    <main>
      <SEO
        title="Prestige Car Leasing Search | Morgan Highfield & Land"
        description="Are you searching for your next prestige car lease? We'll find you the perfect match, without fees or charges because we are #1 for Prestige Car Leasing."
      />

      <LayoutPage bg="#FFF">
        <HeroStatic headline="" image={data.hero} isLarge={matches}>
          <SearchPreferencesWidget />
        </HeroStatic>

        <React.Fragment>
          <Section bg="transparent" nopad>
            <SearchHeader />

            {isLoading ? (
              <Loading message="Loading.." height={250} />
            ) : (
              <React.Fragment>
                {!isEmpty(variants) ? (
                  <PostsList>
                    <React.Fragment>
                      {variants.slice(0, postsToShow).map((post, index) => {
                        count++;

                        if (count === 4) {
                          count = 1;
                        }

                        return (
                          <Grid
                            item
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                            key={`special-offers=${index}`}
                          >
                            <SpecialOffersItem
                              agreementTerms={agreementTerms}
                              annualMileage={annualMileage}
                              initialRental={initialRental}
                              isSearch
                              leaseType={leaseType}
                              variant={post.node}
                              index={count}
                            />
                          </Grid>
                        );
                      })}
                    </React.Fragment>
                  </PostsList>
                ) : (
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item className={classes.noResults}>
                      <SearchOutlinedIcon className={classes.noResultsIcon} />
                      <Typography variant="body2">
                        No results found. Please adjust your search criteria.
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {!isEmpty(variants) && variants.length > postsToShow && (
                  <Container maxWidth="xl" className={classes.loadMorePosts}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <Typography className={classes.loadMoreCount}>
                          You&rsquo;ve viewed {postsToShow} of {variants.length}
                        </Typography>
                        <MuiButton onClick={() => handleRestart()} variant="outlined">
                          <ReplayIcon className={classes.buttonIcon} />
                          Start again
                        </MuiButton>
                        <MuiButton
                          disabled={isLoadingPosts}
                          onClick={() => handlePage()}
                          variant="contained"
                          color="secondary"
                        >
                          {isLoadingPosts ? 'Loading' : 'Load more'}
                        </MuiButton>
                      </Grid>
                    </Grid>
                  </Container>
                )}
              </React.Fragment>
            )}

            <footer className={classes.footer}>
              <Typography className={classes.footerTitle}>
                Initial payment & terms apply, subject to status
              </Typography>
            </footer>
          </Section>

          <Section nopad>
            <div>
              <ContentModulesCallToActionPrimary
                buttons={[
                  {
                    button: {
                      title: 'Take me there',
                      url: '/#module-2',
                    },
                    type: 'block',
                  },
                ]}
                headline="Just a click away"
                image={data.cta}
                subtitle="Prefer to browse by brand?"
              />
            </div>
          </Section>
        </React.Fragment>
      </LayoutPage>

      <BackToTop isVisible anchorId="___gatsby" />

      <Notifications />
    </main>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    agreementTerms: state.specialOffers.agreementTerms,
    annualMileage: state.specialOffers.annualMileage,
    bodyStyles: state.specialOffers.bodyStyles,
    brands: state.specialOffers.brands,
    fuelType: state.specialOffers.fuelType,
    initialRental: state.specialOffers.initialRental,
    isLoading: state.specialOffers.isLoading,
    isLoadingPosts: state.specialOffers.isLoadingPosts,
    leaseType: state.specialOffers.leaseType,
    page: state.specialOffers.page,
    postsPerPage: state.specialOffers.postsPerPage,
    postsToShow: state.specialOffers.postsToShow,
    price: state.specialOffers.price,
    sortValue: state.specialOffers.sortValue,
    transmission: state.specialOffers.transmission,
    variants: state.specialOffers.variants,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleInitialVariants,
      handleGetInitialPreferences,
      handleUpdatePage,
      handleFilterVariants,
    },
    dispatch
  );

interface SeachPageProps {
  data: any; // TODO:
  agreementTerms: number;
  annualMileage: number;
  handleUpdatePage: Function;
  handleInitialVariants: Function;
  handleGetInitialPreferences: Function;
  handleFilterVariants: Function;
  initialRental: number;
  isLoading: boolean;
  isLoadingPosts: boolean;
  page: number;
  pageContext: any;
  postsPerPage: number;
  postsToShow: number;
  variants: Array<WordpressWpCarEdge>;
  leaseType: string;
  initialVariants: Array<WordpressWpCarEdge>;
  fuelType: string;
  brands?: [string];
  bodyStyles: [string];
  transmission?: string;
  sortValue: string;
  price?: Array<number>;
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);

export const query = graphql`
  query searchPageQuery {
    allWordpressWpCar(
      filter: {
        post_meta_fields: {
          variant_url: { ne: "" }
          price_24m: {
            mileage_10k: { ne: 0 }
            mileage_11k: { ne: 0 }
            mileage_12k: { ne: 0 }
            mileage_13k: { ne: 0 }
            mileage_14k: { ne: 0 }
            mileage_15k: { ne: 0 }
            mileage_16k: { ne: 0 }
            mileage_17k: { ne: 0 }
            mileage_18k: { ne: 0 }
            mileage_19k: { ne: 0 }
            mileage_20k: { ne: 0 }
            mileage_8k: { ne: 0 }
            mileage_9k: { ne: 0 }
          }
        }
        status: { eq: "publish" }
        slug: { ne: "dummy" }
        path: {}
        # Disable Mercedes Benz
        brand: { nin: [315] }
      }
    ) {
      edges {
        node {
          id
          title
          wordpress_id
          slug
          title
          acf {
            title
            disable_addons
            special_offer
            special_offer_info_bar
          }
          post_meta_fields {
            capid
            capcode
            body_type
            title
            brand_title
            variant_url
            trim
            fuel
            brands {
              name
              slug
            }
            specs {
              spec_BHP
              spec_0_62
              spec_MPG3
              spec_CO2
            }
            price_24m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
            price_36m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
            price_48m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
          }
        }
      }
    }
    cta: file(relativePath: { eq: "call-to-action/main.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    hero: file(relativePath: { eq: "hero/search_hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          height: 856
          width: 1600
          transformOptions: {
            cropFocus: CENTER
            duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
          }
          layout: CONSTRAINED
        )
      }
    }
  }
`;
