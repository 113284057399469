import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Grid, Box, Card, Slide } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';

import {
  agreementTermsMarks,
  annualMileageMarks,
  bodyTypes,
  fuelTypes,
  initialRentalMarks,
  pricingMarks,
  transmissionTypes,
  leaseTypeButtons,
} from '../constants';

import {
  handleFilterVariants,
  handleGetInitialPreferences,
  handleInitialVariants,
  handleUpdateBodyStyle,
  handleUpdateBrands,
  handleUpdatePage,
  handleUpdatePrice,
  handleUpdateTransmission,
  handleUpdateTerms,
  handleUpdateFuel,
  handleUpdateAnnualMileage,
  handleUpdateInitialRental,
  handleReset,
  handleUpdateLeaseType,
} from '../actions/specialOffers';

import { IAppState, LeaseType } from '../defs/declarations';

import { WordpressWpCarEdge } from '../defs/generated-models';
import InputRange from './InputRange';
import InputSelect from './InputSelect';
import InputSelectMulti from './InputSelectMulti';
import MuiButton from './MuiButton';
import GroupedButton from './GroupedButton';
import TextButton from './TextButton';
import { numberWithCommas, smoothScrollToElement } from '../utils/utils';
import { styleConfig } from '../../config/config';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  noPadding: {
    paddingLeft: '0!important',
    paddingRight: '0!important',
  },
  cardRoot: {
    minHeight: 851,
    padding: '40px 60px',
    position: 'absolute',
    borderRadius: 0,
    zIndex: 750,
    backgroundColor: '#202020',
    top: 25,
    maxWidth: 527,

    [theme.breakpoints.down('md')]: {
      top: 25,
      padding: 15,
      minHeight: 'fit-content',
      maxWidth: '100%',
      borderRadius: 0,
      backgroundColor: 'transparent',
    },
  },
  title: {
    color: 'white',
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: 40,
    lineHeight: '40px',
    marginBottom: 40,
  },
  filterCountRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginBottom: 30,
    fontSize: 12,
    fontWeight: 800,
    letterSpacing: 1,
    color: styleConfig.color.text.white,
    textTransform: 'uppercase',
    lineHeight: '26px',
  },
  filterCountTextSecondary: {
    color: '#595959',
  },
  buttonGroup: {
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: 15,

      '& > button': {
        '&:first-of-type': {
          marginRight: '0!important',
        },

        width: '100%',
        marginBottom: 15,
      },
    },
  },
  buttonRootSecondary: {},
  buttonGroupRight: {
    justifyContent: 'flex-end',
  },
}));

const SearchPreferencesWidget: React.FunctionComponent<SearchPreferencesWidgetProps> = (props) => {
  const classes = useStyles();
  const [isAdvanced, setIsAdvanced] = useState(false);

  const {
    handleUpdateBrands,
    handleUpdateBodyStyle,
    handleUpdatePrice,
    handleUpdateTransmission,
    handleUpdateFuel,
    handleUpdateTerms,
    handleUpdateInitialRental,
    handleUpdateAnnualMileage,
    handleUpdateLeaseType,
    handleReset,
    handleFilterVariants,
    isTitle,
    agreementTerms,
    annualMileage,
    bodyStyles,
    leaseType,
    initialVariants,
    brands,
    fuelType,
    initialRental,
    isLoading,
    price,
    transmission,
    variants,
    postsPerPage,
    sortValue,
  } = props;

  const handleFilter = () => {
    if (props.filterCount < 1) {
      return;
    }

    handleFilterVariants(
      true,
      leaseType,
      agreementTerms,
      initialRental,
      annualMileage,
      initialVariants,
      variants,
      fuelType,
      brands,
      bodyStyles,
      transmission,
      price,
      sortValue,
      postsPerPage
    );
  };

  // Filters
  const handleBrands = (val) => {
    handleUpdateBrands(val);
  };

  const handleBodyStyle = (val) => {
    handleUpdateBodyStyle(val);
  };

  const handlePrice = (val) => {
    if (val !== price) {
      handleUpdatePrice(val);
    }
  };

  const handleTransmission = (val) => {
    if (val !== transmission) {
      handleUpdateTransmission(val);
    }
  };

  const handleFuel = (val) => {
    if (val !== fuelType) {
      handleUpdateFuel(val);
    }
  };

  const handleTerms = (val) => {
    if (val !== agreementTerms) {
      handleUpdateTerms(leaseType, val, initialRental, annualMileage);
    }
  };

  const handleRental = (val) => {
    if (val !== initialRental) {
      handleUpdateInitialRental(leaseType, agreementTerms, val, annualMileage);
    }
  };

  const handleLeaseType = (val) => {
    if (val !== leaseType) {
      handleUpdateLeaseType(val);
    }
  };

  const handleMileage = (val) => {
    if (val !== annualMileage) {
      handleUpdateAnnualMileage(leaseType, agreementTerms, initialRental, val);
    }
  };

  const handleFilterAdvanced = () => {
    setIsAdvanced(false);
  };

  const query = useStaticQuery(graphql`
    {
      allWordpressWpBrand(
        filter: {
          brand_meta_fields: { hasParent: { eq: false } }
          acf: { brand_page: { eq: true } }
          slug: { nin: "demo" }
        }
      ) {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `);

  // All top-level brands based on the above query
  const brandTypes = query.allWordpressWpBrand.edges.map((brand) => {
    return {
      label: brand.node.name,
      value: brand.node.slug,
    };
  });

  useEffect(() => {
    const {
      leaseType,
      agreementTerms,
      initialRental,
      annualMileage,
      initialVariants,
      variants,
      fuelType,
      brands,
      bodyStyles,
      transmission,
      price,
      sortValue,
      postsPerPage,
    } = props;

    handleFilterVariants(
      false,
      leaseType,
      agreementTerms,
      initialRental,
      annualMileage,
      initialVariants,
      variants,
      fuelType,
      brands,
      bodyStyles,
      transmission,
      price,
      sortValue,
      postsPerPage,
      true
    );
  }, [props, handleFilterVariants]);

  return (
    <Box className={classes.root} id="filter-widget">
      <Container maxWidth="xl" className={classes.noPadding}>
        <Grid item xs={12} sm={12} md={12} lg={7} xl={5}>
          <Card className={classes.cardRoot}>
            <h2 className={classes.title}>{isTitle ? '' : 'Find your perfect car today'}</h2>

            <Slide in={!isAdvanced} direction="right" style={{ height: !isAdvanced ? 'auto' : 0 }}>
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <GroupedButton
                    handleClick={handleLeaseType}
                    items={leaseTypeButtons}
                    selected={leaseType}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <InputRange
                    marginBottom={0}
                    isDark
                    isReducedSpacing
                    isMarksHidden
                    unit="Months"
                    fullWidth
                    handleChange={handleRental}
                    labelText="Initial Rental (Months)"
                    marks={initialRentalMarks}
                    max={12}
                    min={3}
                    name="initialRental"
                    step={3}
                    value={initialRental}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <InputRange
                    marginBottom={0}
                    isMarksHidden
                    isDark
                    isReducedSpacing
                    fullWidth
                    handleChange={handleTerms}
                    labelText="Contract Hire Term (Months)"
                    marks={agreementTermsMarks}
                    max={48}
                    min={24}
                    name="agreementTerms"
                    step={12}
                    value={agreementTerms}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <InputRange
                    marginBottom={30}
                    isDark
                    isMarksHidden
                    isReducedSpacing
                    fullWidth
                    handleChange={handleMileage}
                    labelText="Annual Mileage"
                    marks={annualMileageMarks}
                    max={20000}
                    min={8000}
                    name="annualMileage"
                    step={1000}
                    value={annualMileage}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  {fuelType && (
                    <InputSelect
                      isReducedSpacing
                      marginBottom={10}
                      isDark
                      fullWidth
                      handleChange={handleFuel}
                      label
                      labelText="Fuel type"
                      menuItems={fuelTypes}
                      name="fuelType"
                      value={fuelType}
                    />
                  )}
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  {price && handlePrice && (
                    <InputRange
                      marginBottom={30}
                      isDark
                      isMarksHidden
                      isReducedSpacing
                      fullWidth
                      handleChange={handlePrice}
                      isMulti
                      labelText="Monthly price"
                      marks={pricingMarks}
                      max={1500}
                      min={150}
                      name="pricing"
                      step={150}
                      value={price}
                    />
                  )}
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box className={classes.buttonGroup}>
                    <MuiButton
                      disabled={isLoading || props.filterCount < 1}
                      variant="contained"
                      color="primary"
                      onClick={() => handleFilter()}
                    >
                      {(isLoading && 'Please wait..') ||
                        (props.filterCount >= 1 &&
                          `Show cars ${
                            props.filterCount >= 1 && numberWithCommas(props.filterCount)
                          }`) ||
                        'No matches'}{' '}
                    </MuiButton>

                    <TextButton
                      color="#FFF"
                      disabled={isLoading}
                      onClick={() => {
                        smoothScrollToElement('___gatsby');
                        setIsAdvanced(true);
                      }}
                    >
                      Advanced Search <ChevronRightIcon />
                    </TextButton>
                  </Box>
                  <Box className={clsx([classes.buttonGroup, classes.buttonGroupRight])}>
                    {handleReset && (
                      <TextButton color="#FFF" disabled={isLoading} onClick={handleReset}>
                        Reset
                      </TextButton>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Slide>

            <Slide in={isAdvanced} direction="left" style={{ height: isAdvanced ? 'auto' : 0 }}>
              <Grid container spacing={3}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  {brands && (
                    <InputSelectMulti
                      data={brandTypes}
                      isReducedSpacing
                      marginBottom={0}
                      isDark
                      handleChange={handleBrands}
                      label
                      labelText="Brands"
                      placeholder="e.g. BMW"
                      value={brands}
                    />
                  )}
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <InputSelectMulti
                    data={bodyTypes}
                    isDark
                    handleChange={handleBodyStyle}
                    label
                    labelText="Body Style"
                    placeholder="e.g. Estate"
                    value={bodyStyles}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  {transmission && (
                    <InputSelect
                      isDark
                      isReducedSpacing
                      fullWidth
                      handleChange={handleTransmission}
                      label
                      labelText="Transmission"
                      menuItems={transmissionTypes}
                      name="transmission"
                      value={transmission}
                    />
                  )}
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box className={classes.filterCountRoot}>
                    <p>{props.filterCount} Cars Available</p>
                    <p className={classes.filterCountTextSecondary}>Once filters applied</p>
                  </Box>

                  <Box className={clsx([classes.buttonGroup, classes.buttonGroupRight])}>
                    <MuiButton
                      labelColor="#FFF"
                      disabled={isLoading}
                      variant="contained"
                      color="primary"
                      onClick={() => handleFilterAdvanced()}
                    >
                      {(isLoading && 'Pleas wait..') || 'Apply Filters'}
                    </MuiButton>
                  </Box>
                </Grid>
              </Grid>
            </Slide>
          </Card>
        </Grid>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    filterCount: state.specialOffers.filterCount,
    agreementTerms: state.specialOffers.agreementTerms,
    annualMileage: state.specialOffers.annualMileage,
    bodyStyles: state.specialOffers.bodyStyles,
    brands: state.specialOffers.brands,
    fuelType: state.specialOffers.fuelType,
    initialRental: state.specialOffers.initialRental,
    leaseType: state.specialOffers.leaseType,
    initialVariants: state.specialOffers.initialVariants,
    isFiltered: state.specialOffers.isFiltered,
    isLoading: state.specialOffers.isLoading,
    isLoadingPosts: state.specialOffers.isLoadingPosts,
    postsPerPage: state.specialOffers.postsPerPage,
    postsToShow: state.specialOffers.postsToShow,
    price: state.specialOffers.price,
    sortValue: state.specialOffers.sortValue,
    transmission: state.specialOffers.transmission,
    variants: state.specialOffers.variants,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleFilterVariants,
      handleGetInitialPreferences,
      handleInitialVariants,
      handleReset,
      handleUpdateLeaseType,
      handleUpdateAnnualMileage,
      handleUpdateBodyStyle,
      handleUpdateBrands,
      handleUpdateFuel,
      handleUpdateInitialRental,
      handleUpdatePage,
      handleUpdatePrice,
      handleUpdateTerms,
      handleUpdateTransmission,
    },
    dispatch
  );

interface SearchPreferencesWidgetProps {
  filterCount: number;
  agreementTerms: number;
  annualMileage: number;
  bodyStyles?: [string];
  brands?: [string];
  fuelType: string;
  handleUpdateLeaseType: Function;
  handleReset: Function;
  handleUpdateAnnualMileage: Function;
  handleUpdateBodyStyle: Function;
  handleUpdateBrands: Function;
  handleUpdateFuel: Function;
  handleUpdateInitialRental: Function;
  handleUpdatePage: Function;
  handleUpdatePrice: Function;
  handleUpdateTerms: Function;
  handleUpdateTransmission: Function;
  handleFilterVariants: Function;
  initialRental: number;
  initialVariants: Array<WordpressWpCarEdge>;
  isFiltered?: boolean;
  isTitle?: boolean;
  isLoading: boolean;
  isLoadingPosts: boolean;
  leaseType: LeaseType;
  postsPerPage: number;
  postsToShow: number;
  price?: Array<number>;
  sortValue: string;
  transmission?: string;
  variants: Array<WordpressWpCarEdge>;
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPreferencesWidget);
