import React from 'react';
import { rgba } from 'polished';
import { useTheme, Theme } from '@material-ui/core';
import Select from 'react-select';

import { styleConfig } from '../../config/config';

import InputLabel from './InputLabel';

const InputSelectMulti = (props) => {
  const {
    isDark,
    data,
    handleChange,
    label,
    labelText,
    isTooltip,
    tooltipContent,
    required,
    value,
    ...rest
  } = props;

  const theme = useTheme<Theme>();

  const colourStyles = {
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight: 400,
    }),
    control: (styles) => ({
      ...styles,
      '& > div': {
        padding: 0,
      },
      marginTop: 10,
      border: 'none',
      borderBottom: '1px #DDD solid',
      outline: 0,
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#DDD',
      },
      borderRadius: 0,
      borderWidth: 2,
      padding: 10,
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: styleConfig.color.bg.gold,
        color: '#FFF',
        padding: 2,
        fontSize: 16,
        fontWeight: 600,
        borderRadius: 0,

        [theme.breakpoints.down('md')]: {
          fontSize: 14,
        },
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#FFF',
    }),

    multiValueRemove: (styles) => ({
      ...styles,
      padding: 0,
      svg: {
        width: 20,
        height: 25,
        fill: rgba(styleConfig.color.text.darkgrey, 0.4),
        transition: '250ms ease-in-out all',
      },
      ':hover': {
        cursor: 'pointer',
        svg: {
          fill: styleConfig.color.text.darkgrey,
        },
      },
    }),
    group: (styles) => ({
      ...styles,
      backgroundColor: '#FAFAFA',
      padding: '30px 0',
      borderBottom: '1px #DDD solid',
      '&:nth-of-type(odd)': {
        backgroundColor: '#FEFEFE',
      },
    }),
    groupHeading: (styles) => ({
      ...styles,
      fontSize: 17,
      fontWeight: 600,
      fontFamily: 'Bai Jamjuree',
      color: styleConfig.color.text.gold,
      textTransform: 'uppercase',
      marginBottom: 20,
      position: 'relative',
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: 16,

      [theme.breakpoints.down('md')]: {
        fontSize: 13,
      },
    }),
    option: (styles, state) => ({
      ...styles,
      color: styleConfig.color.text.darkgrey,
      cursor: 'pointer',
      padding: 10,
      fontWeight: 600,
      fontSize: 14,
      backgroundColor: state.isSelected ? styleConfig.color.bg.gold : 'none',
      '&:hover': {
        backgroundColor: styleConfig.color.bg.gold,
        color: '#FFF',
      },

      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    }),
  };

  return (
    <div>
      {label && labelText && (
        <InputLabel
          isDark={isDark}
          isTooltip={isTooltip}
          tooltipContent={tooltipContent}
          required={required || false}
        >
          {labelText}
        </InputLabel>
      )}
      <Select
        isSearchable
        isMulti
        onChange={handleChange}
        openMenuOnClick={false}
        options={data}
        styles={colourStyles}
        noOptionsMessage={() => 'No results found'}
        value={value}
        {...rest}
      />
    </div>
  );
};

interface OptionProps {
  data: {
    value: string;
    slug: string;
  };
  handleChange: Function;
  tooltipContent?: string;
  idDark?: boolean;
  isTooltip?: boolean;
  labelText?: string;
  required?: boolean;
  label?: boolean;
  value: [any];
}

export default InputSelectMulti;
