import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import EmailSignup from './EmailSignup';
import Footer from './Footer';
import Header from './Header';

const LayoutPage: React.FunctionComponent<LayoutPageProps> = (props) => {
  const { children, bg } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    pageRoot: {
      backgroundColor: bg ? bg : 'inherit',
      [theme.breakpoints.down('md')]: {
        paddingTop: 105,
      },

      [theme.breakpoints.down('sm')]: {
        paddingTop: 85,
      },
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <Header />

      <div className={classes.pageRoot}>{children}</div>

      <EmailSignup />

      <Footer />
    </React.Fragment>
  );
};

interface LayoutPageProps {
  children: React.ReactNode;
  bg?: string;
}

export default LayoutPage;
